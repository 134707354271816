<template>
  <div class="block-box">
    <div class="block-title">
      <div class="block-title-left">其他项目</div>
      <div class="block-title-right">
        <router-link to="/project"
          ><svg-icon icon-class="circle-plus" /> 查看更多</router-link
        >
      </div>
    </div>
    <div class="block-content recommend-box">
      <div class="recommend-swipe" ref="recommendswipe">
        <router-link
          v-for="(item, idx) in projectList"
          :key="idx"
          :to="`/project/` + item.id"
        >
          <div class="recommend-project">
            <div class="recommend-image">
              <img :src="item.image" alt="" />
            </div>
            <div class="recommend-title">
              <p>{{ item.title }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjectRecommend } from '@/api/project'
export default {
  data () {
    return {
      projectList: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getProjectRecommend().then((response) => {
        this.projectList = response
        let width = 11 * this.projectList.length + 0.5
        this.$refs.recommendswipe.style.width = width + 'rem'
      })
    }
  }
}
</script>

<style>
.recommend-box {
  height: 7rem;
  padding-bottom: 1rem;
  overflow-x: scroll;
}

.recommend-swipe {
}

.recommend-project {
  width: 10rem;
  height: 7rem;
  margin: 0 0.5rem;
  float: left;
}

.recommend-project > div {
  width: 10rem;
}

.recommend-image {
  height: 6rem;
}

.recommend-image img {
  width: 100%;
  height: 100%;
}

.recommend-title {
  height: 1rem;
}

.recommend-title p {
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
