<template>
  <div class="share-guide">
    <van-overlay :show="show" @click="closeOverlay">
      <div class="share-point">
        <p>
          <svg-icon icon-class="trg-top" />
        </p>
      </div>
      <div class="share-text">
        <p>点击右上角菜单</p>
        <p>和朋友分享</p>
      </div>
      <div class="share-image" v-if="shareImg">
        <img :src="shareImg" alt="" />
        <p class="share-tip">长按图片保存到手机，分享到其他平台。</p>
        <p class="share-tip">点击空白处关闭</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { createShareImage } from "@/api/other";
//import { arrayToQuery } from "@/utils/encode";
import { Toast } from "vant";

export default {
  name: "ShareGuide",
  model: {
    prop: "show",
    event: "parent-event",
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    path: {
      type: String,
      default() {
        return this.$route.path;
      },
    },
    title: {
      type: String,
      default() {
        return this.$route.meta.title;
      },
    },
  },
  data() {
    return {
      showOverlay: false,
      shareImg: null,
      newpath: null,
    };
  },
  watch: {
    show() {
      if (this.show && !this.shareImg) {
        console.log("share");
        console.log(this.path);
        this.loadShareImage();
      }
    },
  },
  methods: {
    closeOverlay() {
      this.$emit("parent-event", false);
    },
    loadShareImage() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      createShareImage({
        path: this.path,
        title: this.title,
      }).then((response) => {
        this.shareImg = response.image;
        Toast.clear();
      });
    },
  },
  mounted() {
    if (this.show) {
      this.showOverlay = true;
    }
  },
};
</script>

<style>
.share-guide > .van-overlay {
  z-index: 999999;
}
.share-image {
  width: 75%;
  margin: 0 auto;
  position: relative;
  top: 30%;
}

.share-image img {
  width: 100%;
}

.share-tip {
  text-align: center;
  font-size: 0.7rem;
  color: #ffffff;
}

.share-text {
  position: absolute;
  width: 7rem;
  height: 2.6rem;
  background-color: antiquewhite;
  padding: 0.5rem;
  right: 5px;
  top: 20px;
  border-radius: 10px;
}

.share-text p {
  text-align: center;
  font-size: 1rem;
  margin: 0;
  line-height: 1.2rem;
}

.share-point {
  position: absolute;
  right: 10px;
  top: 5px;
}

.share-point p {
  margin: 0;
  color: antiquewhite;
  font-size: 30px;
  line-height: 30px;
}
</style>
