<template>
  <div class="page-container">
    <div
      class="success-banner"
      :style="{ backgroundImage: `url(${bannerImage})` }"
    >
      <div class="banner-avatar">
        <img :src="item.avatar" alt="" />
      </div>
    </div>
    <div class="success-message">
      <p class="message-content" style="text-indent: 0">
        {{ item.name }}捐赠{{ item.amount }}元
      </p>
      <p class="message-content">{{ item.message }}</p>
      <p class="message-author">{{ item.time }}</p>
    </div>
    <project-info
      v-if="item.project_id"
      class="project-info-box"
      :title="item.project_title"
      :image="item.project_image"
      :pid="item.project_id"
      :amount="item.project_amount"
      :member="item.project_member"
    />

    <div class="success-button">
      <van-row>
        <van-col span="11">
          <van-button
            @click="openCertOverlay"
            size="large"
            round
            type="info"
            color="#FF0000"
            >捐赠证书</van-button
          >
        </van-col>
        <van-col span="12" offset="1">
          <template v-if="this.item.donate_user === this.item.view_user">
            <van-button
              @click="showShareGuide = true"
              size="large"
              round
              type="info"
              color="#FF0000"
              >我要分享</van-button
            >
          </template>
          <template v-else>
            <van-button
              @click="enterProject"
              size="large"
              round
              type="info"
              color="#FF0000"
              >我要参与</van-button
            >
          </template>
        </van-col>
      </van-row>
    </div>
    <template
      v-if="this.item.donate_user === this.item.view_user && item.show_guide"
    >
      <div class="block-box" style="margin-top: 0.9rem">
        <div class="block-title">
          <div class="block-title-left">关注进展</div>
        </div>
        <div class="block-content">
          <img class="gzh-guide-image" :src="GzhQrcode" alt="" />
        </div>
      </div>
    </template>
    <div class="recommend">
      <project-recommend />
    </div>
    <share-guide
      v-model="showShareGuide"
      :title="this.item.project_title"
      :path="this.$route.path"
    />
    <van-overlay :show="showCert" @click="showCert = false">
      <div class="cert-box">
        <img :src="certImg" alt="" />
        <p class="cert-tip">长按证书保存到手机，和朋友分享。</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
//import { getStorage } from "@/utils/storage";
import { getCertImageByDonateNo, getDonateInfo } from "@/api/donate";
import ShareGuide from "@/components/ShareGuide";
import ProjectRecommend from "@/components/ProjectRecommend";
import ProjectInfo from "@/components/ProjectInfo";
import { Toast } from "vant";
import BannerImage from "@/assets/images/1_1.png";
import GzhQrcode from "@/assets/images/gzh_guide.png";

export default {
  components: { ShareGuide, ProjectRecommend, ProjectInfo },
  data() {
    return {
      donate_no: null,
      avatarUrl:
        "https://blog.imea.me/wp-content/uploads/2020/02/158296547938.jpeg",
      showShareGuide: false,
      showCert: false,
      certImg: null,
      item: {
        name: null,
        avatar: null,
        amount: 0,
        time: null,
        message: null,
        project_id: null,
        project_title: null,
        project_count: 0,
        project_amount: 0,
        project_image: null,
        donate_user: null,
        view_user: null,
        show_guide: false,
      },
      bannerImage: BannerImage,
      GzhQrcode: GzhQrcode,
    };
  },
  mounted() {
    this.donate_no = this.$route.params.donate_no;
    this.fetchData();
  },
  methods: {
    fetchData() {
      getDonateInfo(this.donate_no).then((response) => {
        this.item = response;
      });
    },
    openCertOverlay() {
      this.showCert = true;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getCertImageByDonateNo(this.donate_no).then((response) => {
        this.certImg = response.image;
        Toast.clear();
      });
    },
    enterProject() {
      this.$router.push({
        path: "/project/" + this.item.project_id + "/project",
        query: { inviter: this.item.donate_user },
      });
    },
  },
};
</script>

<style>
.success-banner {
  width: 100%;
  height: 12rem;
  background-size: cover;
  border-bottom-left-radius: 75%;
  border-bottom-right-radius: 75%;
}

.banner-avatar {
  text-align: center;
  padding-top: 7.5rem;
}

.banner-avatar img {
  width: 7rem;
  border-radius: 50%;
}

.success-message {
  width: 65%;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1rem 2rem;
  min-height: 5rem;
  background-color: beige;
  border-radius: 10px;
  font-size: 1rem;
}

.message-content {
  line-height: 1.5rem;
  line-break: auto;
  text-indent: 1rem;
}

.message-author {
  text-align: right;
}

.success-button {
  width: 75%;
  margin: 0 auto;
  margin-top: 1rem;
}

.cert-box {
  width: 18rem;
  height: 32rem;
  margin: 0 auto;
  margin-top: 7rem;
}

.cert-box img {
  width: 18rem;
}

.success-project {
  height: 4rem;
  width: 78%;
  margin: 0 auto;
  margin-top: 1rem;
  border: 1px solid #000000;
}

.success-project-img {
  height: 4rem;
  width: 100%;
}

.more-button-icon {
  line-height: 3.7rem;
  font-size: 2rem;
}

.success-project-text {
  margin: 0.5rem 1rem;
}

.success-project-text p {
  margin: 0;
  line-height: 1.5rem;
}

.success-project-title {
  font-size: 0.9rem;
}

.success-project-stats {
  font-size: 0.7rem;
}

.project-info-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
}

.recommend {
  margin-top: 0.7rem;
}

.cert-tip {
  text-align: center;
  font-size: 0.7rem;
  color: #ffffff;
}

.gzh-guide-image {
  width: 100%;
}
</style>
