import request from '@/utils/request'

export function getProjectList (search = null) {
  return request({
    url: '/project/list',
    method: 'get',
    params: {search: search}
  })
}

export function getProjectDetail (project_id) {
  return request({
    url: '/project/detail',
    method: 'get',
    params: { project: project_id }
  })
}

export function getProjectTitle (project_id) {
  return request({
    url: '/project/title',
    method: 'get',
    params: { project: project_id }
  })
}

export function getProjectRecommend () {
  return request({
    url: '/project/recommend',
    method: 'get'
  })
}

export function getProjectDynamic (project_id) {
  return request({
    url: '/project/dynamic',
    method: 'get',
    params: { project: project_id }
  })
}

export function starProject (project) {
  return request({
    url: '/project/star',
    method: 'post',
    data: { project: project }
  })
}

export function unstarProject (project) {
  return request({
    url: '/project/unstar',
    method: 'delete',
    data: { project: project }
  })
}

export function getProjectSelecter (project_id) {
  return request({
    url: '/project/selecter',
    method: 'get',
    params: { project: project_id }
  })
}

export function getProjectDanmu (project) {
  return request({
    url: '/project/danmu',
    method: 'get',
    params: { project: project }
  })
}