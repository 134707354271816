<template>
  <div v-if="pid">
    <router-link :to="routerTo">
      <div class="project-info">
        <van-row>
          <van-col span="7">
            <div class="project-info-image">
              <img :src="image" alt="" />
            </div>
          </van-col>
          <van-col span="15">
            <div class="project-info-text">
              <p class="project-info-title">{{ title }}</p>
              <p class="project-info-stats">
                <svg-icon icon-class="times" /> 共有<span class="red-text">{{
                  member
                }}</span
                >人参与
              </p>
              <p class="project-info-stats">
                <svg-icon icon-class="money" /> 已筹款<span class="red-text">{{
                  amount
                }}</span
                >元
              </p>
            </div>
          </van-col>
          <van-col span="2">
            <span class="project-info-more">
              <svg-icon icon-class="point-right" />
            </span>
          </van-col>
        </van-row>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    pid: Number,
    image: String,
    title: String,
    member: Number,
    amount: Number,
    inviter: {
      type: String,
      default () {
        return null
      }
    },
    group: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      routerTo: ''
    }
  },
  mounted () {
    let projectUrl = '/project/' + this.pid + '/project'
    let projectParam = {}
    if (this.inviter !== null) {
      projectParam['inviter'] = this.inviter
    }
    if (this.group !== null) {
      projectParam['group'] = this.group
    }
    console.log(projectParam)

    let query = this.arrayToQuery(projectParam)
    if (query.length) {
      this.routerTo = projectUrl + '?' + query
    } else {
      this.routerTo = projectUrl
    }
  },
  methods: {
    arrayToQuery (arr = {}) {
      let query = ''
      for (let key in arr) {
        let value = arr[key]
        query += `${key}=${value}&`
      }

      return query.slice(0, query.length - 1)
    }
  }
}
</script>

<style>
.project-info {
  height: 6rem;
  border: 1px solid #f7f7f7;
  background-color: #ffffff;
}

.project-info-image img {
  width: 6rem;
  height: 6rem;
}

.project-info-more {
  font-size: 2rem;
  line-height: 6rem;
}

.project-info-text {
  width: 90%;
  margin: 0 auto;
}

.project-info-text p {
  margin: 0;
}

.project-info-title {
  font-size: 1.1rem;
  line-height: 3rem;
  display: inline-block;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-info-stats {
  font-size: 0.8rem;
  line-height: 1.3rem;
}
</style>
